import React from 'react'

export default function four_oh_four() {
    return (
        <div>
            Nie znaleziono strony (error 404).
            <br />
            <a href="https://korkizfrontendu.pl">Powrót na korkizfrontendu.pl</a>
        </div>
    )
}
